import { Box, Typography } from '@mui/material';
import { subDays } from 'date-fns';

import { theme } from 'themes/defaultTheme';

export const DateTimeFiltersShort = ({ column, intervalText, nrDays }: any) => {
  return !column?.getFilterValue()?.[intervalText] ? (
    <Box
      onClick={() =>
        column.setFilterValue({
          from: subDays(new Date(), nrDays),
          to: null,
          [intervalText]: true,
        })
      }
      sx={{
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        border: '1px solid #e1e1e1',
        borderRadius: 1,
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
          cursor: 'pointer',
        },
      }}
    >
      <Typography
        variant="h6"
        component="h6"
        sx={{ fontSize: '12px', color: theme.palette.primary.main, alignSelf: 'center' }}
      >
        {intervalText}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h6"
        component="h6"
        sx={{ fontSize: '12px', color: theme.palette.common.white, alignSelf: 'center' }}
      >
        {intervalText}
      </Typography>
    </Box>
  );
};
