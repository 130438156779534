import { translate } from '../i18n/i18n';

export const CLIENT_INDIVIDUAL = 'individual';
export const CLIENT_COMPANY = 'company';
export const CLIENT_ID_TENANT = 'tenant';

export const getClientFullName = (client) => {
  if (client?.details?.type === CLIENT_COMPANY && client?.details?.companyName) {
    return client?.details?.companyName;
  }

  return `${client?.user?.firstName ?? ''} ${client?.user?.lastName ?? ''}`;
};

export const getObjectClientName = (object, ignoreSelf = false) => {
  const { clientId, clientName, partnerId, partnerName } = object;

  if (clientId && clientName) {
    if (ignoreSelf) {
      return clientName;
    }

    return `${clientName}${clientId === 'tenant' ? ` ${translate('global.label.clientSelf')}` : ''}`;
  }

  if (partnerId && partnerName) {
    if (ignoreSelf) {
      return partnerName;
    }
    return `${partnerName} ${translate('global.label.clientSelf')}`;
  }

  return '';
};

export const getObjectPartnerName = (object) => {
  const { clientId, clientName, partnerId, partnerName } = object;

  if (partnerId && partnerName) {
    return partnerName;
  }

  if (clientId === 'tenant') {
    return getObjectClientName(object);
  }

  return '';
};

export const adaptClientFromListApiToSelect = (client, userClientId) => {
  if (!client) {
    return null;
  }
  return {
    id: client.id,
    label: client.id === userClientId ? `${client.name} ${translate('global.label.clientSelf')}` : client.name,
    parentId: client.parentId,
    level: client.level,
    currency: getClientCurrency(client),
    vat: client.billing?.vat ?? 0,
  };
};

export const adaptClientObjectToSelect = (client, ignoreSelf = false) => {
  if (!client) {
    return null;
  }
  const level = client.clientId ? 1 : 2;

  return {
    id: client.clientId ?? client.partnerId,
    label: getObjectClientName(client, ignoreSelf),
    parentId: client.partnerId,
    level: client.clientId === 'tenant' ? 3 : level,
  };
};

export const adaptClientObjectToSelectIgnoreSelf = (client, clientLevel) => {
  if (client.clientId === 'tenant' || clientLevel === 3) {
    return null;
  }

  if (clientLevel === 1 && !client.clientId) {
    return null;
  }

  return adaptClientObjectToSelect(client, true);
};

export const hasClientInvalidBilling = (client) => {
  return !!client?.id?.length && !(client?.currency || getClientCurrency(client));
};

export const isClientTenant = (client) => {
  return client?.id === CLIENT_ID_TENANT;
};

export const getClientCurrency = (client) => {
  return client?.billing?.currency ?? client?.parentBilling?.currency;
};
