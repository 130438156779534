import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import roTranslation from './translations/ro.json';
import deTranslation from './translations/de.json';
import esTranslation from './translations/es.json';
import itTranslation from './translations/it.json';
import frTranslation from './translations/fr.json';
import ptTranslation from './translations/pt.json';
import huTranslation from './translations/hu.json';

import { loadFromLocalStorage, saveToLocalStorage } from '../storage';
import { getCountryCodes } from './helper';

const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  de: {
    translation: deTranslation,
  },
  es: {
    translation: esTranslation,
  },
  it: {
    translation: itTranslation,
  },
  ro: {
    translation: roTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  hu: {
    translation: huTranslation,
  },
};

const localStorageLang = loadFromLocalStorage('locale') || 'en';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    supportedLngs: [...getCountryCodes()],
    lng: localStorageLang || 'en',
    fallbackLng: localStorageLang || 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

interface IParams {
  [key: string]: string | number;
}

export const translate = (key: string, params: IParams | undefined = undefined) => i18n.t(key, params);

export const changeLocale = async (language: string) => {
  saveToLocalStorage('locale', language);
  i18n
    .changeLanguage(language)
    .then(() => {
      console.log('Language changed successfully');
    })
    .catch((err) => {
      console.log('Error changing language', err);
    });
};

export const getLocale = () => i18n.language;
