import { CustomPalette } from '../types';

type TenantColors = {
  [key: string]: {
    main: string;
    light: string;
    dark: string;
  };
};

const tenantColors: TenantColors = {
  'app.ev-nova.ro': {
    main: '#f02d4a',
    light: '#ff304b',
    dark: '#d6283f',
  },
  'ngtcharge.ro': {
    main: '#0d0828',
    light: '#150d42',
    dark: '#05030f',
  },
  default: {
    main: '#2a76f4',
    light: '#2d7eff',
    dark: '#276bd8',
  },
};

const getTenantColors = (): { main: string; light: string; dark: string } => {
  const currentTenant =
    Object.keys(tenantColors).find((tenant) => window.location.origin.includes(tenant)) || 'default';
  return tenantColors[currentTenant];
};

export const customPalette = {
  palette: {
    primary: getTenantColors(),
    customColors: {
      backgroundDrawer: '#160833',
      icon: '#737373',
      toggleButton: '#506176',
      text: '#03060B',
      excelGreen: '#1f6e43',
      statuses: {
        green: '#66bb6a',
        blue: '#29b6f6',
        yellow: '#ffc400',
        red: '#f44336',
        grey: '#9e9e9e',
        purple: '#9c27b0',
        orange: '#ff9100',
        fuxia: '#fa169f',
      },
      widgets: {
        nr1: '#009688',
        nr2: '#ff9800',
        nr3: '#0d8cc6',
        nr4: '#f04134',
        nr5: '#26b56e',
        nr6: '#6a5d7b',
        nr7: '#00afb9',
      },
    },
  },
} as { palette: CustomPalette };
