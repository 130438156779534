import { Box, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { alpha } from '@mui/material/styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTranslation } from 'react-i18next';

import { theme } from 'themes/defaultTheme';
import { STATUS_AVAILABLE, STATUS_ERROR, STATUS_IN_USE, STATUS_NOT_ACTIVATED, STATUS_OFFLINE } from '../models/station';
import {
  CARD_STATUS_ACTIVE,
  CARD_STATUS_DEACTIVATED,
  CARD_STATUS_EXPIRED,
  CARD_STATUS_NOT_ACTIVATED,
} from '../models/card';
import { connectorStatusColor, getConnectorStatusLabel, showMaxPowerAsString } from '../models/connector';
import { STATUS_SUCCESS } from '../constants';
import { chargingSessionStatus } from './commonMethods';

export const emptyColumn = '-';

export const getIsLoading = (status) => !(status === STATUS_SUCCESS);

export const convertDate = (date) => format(new Date(date), 'dd MMM yyy, hh:mm a');

export const convertDateFull = (date) => format(new Date(date), 'dd MMM yyy, hh:mm:ss a');

export const allStations = {
  [STATUS_AVAILABLE]: {
    name: `station.status.${STATUS_AVAILABLE}`,
    color: theme.palette.customColors.statuses.green,
  },
  [STATUS_IN_USE]: {
    name: `station.status.${STATUS_IN_USE}`,
    color: theme.palette.customColors.statuses.blue,
  },
  [STATUS_ERROR]: {
    name: `station.status.${STATUS_ERROR}`,
    color: theme.palette.customColors.statuses.red,
  },
  [STATUS_OFFLINE]: {
    name: `station.status.${STATUS_OFFLINE}`,
    color: theme.palette.common.black,
  },
  [STATUS_NOT_ACTIVATED]: {
    name: `station.status.${STATUS_NOT_ACTIVATED}`,
    color: theme.palette.customColors.statuses.grey,
  },
};

export const allCards = {
  [CARD_STATUS_ACTIVE]: {
    name: `card.status.${CARD_STATUS_ACTIVE}`,
    color: theme.palette.customColors.statuses.green,
  },
  [CARD_STATUS_DEACTIVATED]: {
    name: `card.status.${CARD_STATUS_DEACTIVATED}`,
    color: theme.palette.common.black,
  },
  [CARD_STATUS_NOT_ACTIVATED]: {
    name: `card.status.${CARD_STATUS_NOT_ACTIVATED}`,
    color: theme.palette.customColors.statuses.grey,
  },
  [CARD_STATUS_EXPIRED]: {
    name: `card.status.${CARD_STATUS_EXPIRED}`,
    color: theme.palette.customColors.statuses.red,
  },
};

export const logsStations = {
  ok: {
    name: 'station.logs.status.ok',
    color: theme.palette.customColors.statuses.blue,
  },
  success: {
    name: 'station.logs.status.success',
    color: theme.palette.customColors.statuses.green,
  },
  info: {
    name: 'station.logs.status.info',
    color: theme.palette.customColors.statuses.yellow,
  },
  debug: {
    name: 'station.logs.status.debug',
    color: theme.palette.common.black,
  },
  error: {
    name: 'station.logs.status.error',
    color: theme.palette.customColors.statuses.red,
  },
};

export const chargingHistoryStatuses = {
  Completed: {
    name: 'chargingHistory.status.Completed',
    color: theme.palette.customColors.statuses.green,
  },
  Charging: {
    name: 'chargingHistory.status.Charging',
    color: theme.palette.customColors.statuses.blue,
  },
  Occupied: {
    name: 'chargingHistory.status.Occupied',
    color: theme.palette.customColors.statuses.yellow,
  },
  // Unknown: {
  //   name: 'chargingHistory.status.Unknown',
  //   color: theme.palette.customColors.statuses.grey,
  // },
};

export const driverBillingStatuses = {
  success: {
    name: 'billing.status.success',
    color: theme.palette.customColors.statuses.green,
  },
  pending: {
    name: 'billing.status.pending',
    color: theme.palette.customColors.statuses.yellow,
  },
  refunded: {
    name: 'billing.status.refunded',
    color: theme.palette.grey[500],
  },
};

export const cellNamePrimaryMain = (value) => (
  <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>{value ?? ''}</span>
);

export const cellDate = (value) => (
  // <Tooltip title={convertDateFull(value)}>
  <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>{value ? convertDate(value) : ''}</span>
  // </Tooltip>
);

export const showLogDetails = (logDetails) => {
  if (typeof logDetails !== 'string' || !logDetails.trim()) {
    return '{}';
  }

  const logsArray = logDetails.split('|||');
  if (logsArray.length === 1 && logsArray[0].trim()) {
    try {
      return JSON.stringify(JSON.parse(logsArray[0]), null, 4);
    } catch (e) {
      return '{}';
    }
  }
  if (logsArray.length === 2) {
    try {
      const doubleLogsMessage = {
        'Station message': { ...JSON.parse(logsArray[0]) },
        'Central System message': { ...JSON.parse(logsArray[1]) },
      };
      return JSON.stringify(doubleLogsMessage, null, 4);
    } catch (e) {
      return '{}';
    }
  }
  return '{}';
};

export const cellStatusError = (value) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'inline-flex',
        backgroundColor: theme.palette.customColors.statuses.red,
        borderRadius: 1,
        p: '4px 10px',
        alignItems: 'center',
      }}
    >
      <span style={{ color: 'white' }}>{value}</span>
    </Box>
  );
};

export const cellStatus = (obj, value) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'inline-flex',
        backgroundColor: value ? obj?.[value]?.color : theme.palette.common.black,
        borderRadius: 1,
        p: '4px 10px',
        alignItems: 'center',
      }}
    >
      <span style={{ color: 'white' }}>{value ? t(obj?.[value]?.name) : t('chargingHistory.status.Unknown')}</span>
    </Box>
  );
};

export const cellChargingSessionsStatus = (obj, value, disconnectedTime = null) => {
  const { t } = useTranslation();

  const formatTimeDifference = (targetDate) => {
    const now = new Date();
    const diffInMilliseconds = targetDate - now;

    const absDiffInMilliseconds = Math.abs(diffInMilliseconds);

    const totalSeconds = Math.floor(absDiffInMilliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    let result = '';

    if (days > 0) result += `${days}d `;
    if (hours > 0) result += `${hours}h `;
    if (minutes > 0) result += `${minutes}m `;
    if (days === 0 && hours === 0 && minutes === 0) result += `${seconds}s`;

    return result.trim();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          display: 'inline-flex',
          backgroundColor: obj?.[value]?.color || theme.palette.customColors.statuses.grey,
          borderRadius: 1,
          p: '4px 10px',
          alignItems: 'center',
        }}
      >
        <span style={{ color: 'white' }}>{chargingSessionStatus(value, t)}</span>
      </Box>
      {disconnectedTime !== null && (value === 'Charging' || value === 'Occupied') && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Tooltip
            enterTouchDelay={0}
            title={`${t('chargingHistory.disconnectInfo')} ${formatTimeDifference(new Date(disconnectedTime))}`}
          >
            <WarningRoundedIcon sx={{ color: theme.palette.customColors.statuses.yellow, ml: 1 }} />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export function DefineCellConnectors({ listConnectors }) {
  const { t } = useTranslation();

  return (
    /* eslint-disable */
    <>
      {listConnectors?.map(({ status, maxPower, connectorType }, idx) => (
        <Tooltip
          key={idx}
          title={t(
            getConnectorStatusLabel(connectorStatusColor?.[status]?.shortName || connectorStatusColor?.[status]?.name)
          )}
        >
          <div style={{ display: 'flex', alignItems: 'center', margin: '3px 0' }}>
            <Typography
              sx={{
                borderRadius: 50,
                backgroundColor: connectorStatusColor?.[status]?.shortColor || connectorStatusColor?.[status]?.color,
                width: 9,
                height: 9,
                mr: 1,
                mb: '2px',
              }}
            />
            <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 600, mr: 1 }}>
              {maxPower ? `${showMaxPowerAsString(maxPower)} kW` : `${t('global.label.connector')} ${idx + 1}`}
            </Typography>
            <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11 }}>
              {connectorType}
            </Typography>
          </div>
        </Tooltip>
      ))}
    </>
    /* eslint-enable */
  );
}

export function DefineCellString(listConnectors, t) {
  const arrConnectors = listConnectors?.map(({ status, maxPower, connectorType }, idx) => {
    const strMaxPower = maxPower ? `${showMaxPowerAsString(maxPower)} kW` : `${t('global.label.connector')} ${idx + 1}`;
    const shortStatus = t(
      getConnectorStatusLabel(connectorStatusColor?.[status]?.shortName || connectorStatusColor?.[status]?.name)
    );
    return `${shortStatus} ${strMaxPower} ${connectorType}`;
  });
  return arrConnectors?.join('\n') ?? '';
}

// stations models
export const TrueFalseIcons = ({ type }) => {
  if (type) {
    return <CheckCircleOutlineIcon sx={{ color: theme.palette.customColors.statuses.green }} />;
  }
  return <HighlightOffIcon sx={{ color: theme.palette.customColors.statuses.red }} />;
};

export const StationsModelsConnectors = ({ listConnectors }) => (
  <table>
    <tbody>
      {listConnectors.map(({ maxPower, connectorType }, idx) => (
        <tr key={idx}>
          <td style={{ paddingRight: '8px', backgroundColor: 'transparent' }}>
            <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 700 }}>
              {showMaxPowerAsString(maxPower)} kW
            </Typography>
          </td>
          <td style={{ paddingRight: '8px', backgroundColor: 'transparent' }}>
            <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 500 }}>
              {connectorType}
            </Typography>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const StationsModelsConnectorsString = (listConnectors) => {
  const arrModelsConnectors = listConnectors.map(({ maxPower, connectorType }) => {
    return `${showMaxPowerAsString(maxPower)} kW  ${connectorType}`;
  });
  return arrModelsConnectors.join('\n');
};

// station charging profile
export const ChargingProfileDay = ({ day }) => {
  const { t } = useTranslation();

  if (day && day.length > 0) {
    return (
      <table
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0 5px',
        }}
      >
        <tbody>
          <tr key={-1}>
            <td>
              <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 700 }}>
                {t('global.label.from')}
              </Typography>
            </td>
            <td>
              <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 700 }}>
                {t('global.label.max')}
              </Typography>
            </td>
          </tr>
          {day.map(({ start, limit }, idx) => (
            <tr
              key={idx}
              style={{
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <td
                style={{
                  padding: '5px',
                  paddingRight: '5px',
                  borderWidth: '1px 0 1px 1px',
                  borderColor: theme.palette.grey[300],
                  borderStyle: 'solid',
                  borderRadius: '5px 0 0 5px',
                }}
              >
                <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 500 }}>
                  {start}
                </Typography>
              </td>
              <td
                style={{
                  padding: '5px',
                  paddingRight: '5px',
                  borderWidth: '1px 1px 1px 0',
                  borderColor: theme.palette.grey[300],
                  borderStyle: 'solid',
                  borderRadius: '0 5px 5px 0',
                }}
              >
                <Typography variant="subtitle4" component="h6" sx={{ fontSize: 11, fontWeight: 500 }}>
                  {`${limit} A`}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return null;
};

export const ChargingProfileDayString = (day) => {
  if (day && day.length > 0) {
    const arrDay = day.map(({ start, limit }) => `${start} ${limit} A`);
    return arrDay.join('\n');
  }
  return '';
};

// clients
export const getClientName = (row) => {
  if (row?.details?.companyName) {
    return row.details.companyName;
  }
  return `${row?.user?.firstName} ${row?.user?.lastName}`;
};

export const ClientTypeName = ({ type }) => {
  if (type === 'Individual')
    return (
      <Box sx={{ display: 'flex' }}>
        <AccountCircleIcon sx={{ mr: 1, color: theme.palette.grey[500] }} />
        <Typography variant="subtitle3" component="span" sx={{ fontSize: '13px', fontWeight: 500, mt: 0.2 }}>
          {type}
        </Typography>
      </Box>
    );
  return (
    <Box sx={{ display: 'flex' }}>
      <LocationCityOutlinedIcon sx={{ mr: 1, color: theme.palette.grey[500] }} />
      <Typography variant="subtitle3" component="span" sx={{ fontSize: '13px', fontWeight: 500, mt: 0.2 }}>
        {type}
      </Typography>
    </Box>
  );
};

// station charging sessions
export const ConnectorIndex = ({ index }) => (
  <Box
    sx={{
      width: '26px',
      height: '26px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[200],
    }}
  >
    <Typography variant="subtitle3" component="span" sx={{ fontSize: '14px', fontWeight: 500 }}>
      {index}
    </Typography>
  </Box>
);

// import from excel
export const ImportValidRow = ({ isValid }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon sx={{ fontSize: 28, color: theme.palette.customColors.statuses.green }} />
    ) : (
      <CancelIcon sx={{ fontSize: 28, color: theme.palette.customColors.statuses.red }} />
    )}
  </Box>
);

export const ImportCell = ({ cellValue, cellMessage }) => {
  if (cellMessage) {
    return (
      <Tooltip title={cellMessage}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ErrorOutlineIcon sx={{ color: theme.palette.customColors.statuses.red, mr: 1 }} />
          <span
            style={{
              paddingTop: 2,
              color: theme.palette.customColors.statuses.red,
              fontWeight: 600,
            }}
          >
            {cellValue}
          </span>
        </Box>
      </Tooltip>
    );
  }
  return (
    <Box sx={{ alignItems: 'center' }}>
      <span style={{ paddingTop: 2 }}>{cellValue}</span>
    </Box>
  );
};

// charging sessions duration
export const DurationTime = ({ time, type = 'charging' }) => (
  <Box
    sx={{
      display: 'inline-flex',
      backgroundColor:
        type === 'charging' ? theme.palette.customColors.statuses.blue : theme.palette.customColors.statuses.yellow,
      borderRadius: 1,
      p: '4px 10px',
      alignItems: 'center',
    }}
  >
    <span style={{ color: 'white' }}>{time}</span>
  </Box>
);

// smart charging group
export const SmartChargingActive = ({ status }) => (
  <Box
    sx={{
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: status ? theme.palette.customColors.statuses.green : theme.palette.grey[300],
    }}
  />
);

export const NoStations = ({ no, color }) => (
  <Box
    sx={{
      display: 'flex',
      backgroundColor: color,
      borderRadius: 1,
      width: '50px',
      p: '4px 10px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <span style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>{no}</span>
  </Box>
);

export const NumberCentered = ({ no, color }) => (
  <Box
    sx={{
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        backgroundColor: color,
        borderRadius: 1,
        width: '50px',
        p: '4px 10px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>{no}</span>
    </Box>
  </Box>
);

export const LimitedBar = ({ percentage }) => (
  <Box
    sx={{
      display: 'flex',
      width: '90%',
      backgroundColor: alpha(theme.palette.customColors.statuses.green, 0.33),
      borderRadius: 1,
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        width: `${percentage}%`,
        backgroundColor: theme.palette.customColors.statuses.green,
        borderRadius: 1,
        p: '4px 0px',
        textAlign: 'center',
      }}
    >
      <span style={{ color: 'white', fontSize: 13 }}>{percentage}%</span>
    </Box>
  </Box>
);
