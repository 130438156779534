import React, { Fragment, useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';

import { theme } from 'themes/defaultTheme';
import { loadIcon } from 'utils/icons';
import { buildNavTree } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const tenantColors = {
  'ngtcharge.ro': {
    selectedBgColor: '#75dcfb',
    selectedTxtColor: '#0d0828',
    selectedIconsColor: '#0d0828',
  },
  default: {
    selectedBgColor: theme.palette.primary.main,
    hoverBgColor: '#4b445f',
    selectedTxtColor: '#ffffff',
    hoverTxtColor: '#ffffff',
    normalTxtColor: '#ffffff',
    selectedIconsColor: '#ffffff',
    hoverIconsColor: '#bfbfbf',
    normalIconsColor: '#bfbfbf',
    titleTxtColor: '#ffffff',
  },
};

const getTenantColors = () => {
  const currentTenant =
    Object.keys(tenantColors).find((tenant) => window.location.origin.includes(tenant)) || 'default';
  return tenantColors[currentTenant];
};

const selectedBgColor = getTenantColors()?.selectedBgColor || tenantColors.default.selectedBgColor;
const hoverBgColor = getTenantColors()?.hoverBgColor || tenantColors.default.hoverBgColor;
const selectedTxtColor = getTenantColors()?.selectedTxtColor || tenantColors.default.selectedTxtColor;
const hoverTxtColor = getTenantColors()?.hoverTxtColor || tenantColors.default.hoverTxtColor;
const normalTxtColor = getTenantColors()?.normalTxtColor || tenantColors.default.normalTxtColor;
const selectedIconsColor = getTenantColors()?.selectedIconsColor || tenantColors.default.selectedIconsColor;
const hoverIconsColor = getTenantColors()?.hoverIconsColor || tenantColors.default.hoverIconsColor;
const normalIconsColor = getTenantColors()?.normalIconsColor || tenantColors.default.normalIconsColor;
const titleTxtColor = getTenantColors()?.titleTxtColor || tenantColors.default.titleTxtColor;
const titleTxtAlpha = 0.66;

const sidebarMenuStyle = {
  // apply to all
  transition: 'none !important',
  '& *': {
    transition: 'none !important',
  },
  // default state
  '& svg': {
    color: normalIconsColor,
  },
  '& .MuiTypography-root': {
    color: normalTxtColor,
  },
  // hover
  ':hover': {
    '& svg': {
      color: hoverIconsColor,
    },
    '& .MuiTypography-root': {
      color: hoverTxtColor,
    },
    backgroundColor: hoverBgColor,
  },
  // selected
  '&.Mui-selected': {
    // selected and hover
    ':hover': {
      '& svg': {
        color: selectedIconsColor,
      },
      '& .MuiTypography-root': {
        color: selectedTxtColor,
      },
      backgroundColor: selectedBgColor,
    },
    '& svg': {
      color: selectedIconsColor,
    },
    '& .MuiTypography-root': {
      color: selectedTxtColor,
    },
    backgroundColor: selectedBgColor,
  },
};

const subMenuSelectedStyle = { style: { color: selectedTxtColor } };

export const SidebarMenu = ({ hideDrawer, isMobile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarMenu = useSelector((state) => state.sidebarMenu.sidebarMenu);
  const [menu, setMenu] = useState(buildNavTree(sidebarMenu, location));

  useEffect(() => {
    setMenu(buildNavTree(sidebarMenu, location));
  }, [sidebarMenu, location]);

  const toggleMenuBranch = (clickedBranch) => {
    setMenu((prevState) =>
      prevState.map((group) => {
        const children = group.children.map((subGroup) => {
          if (subGroup.label === clickedBranch.label) {
            return { ...subGroup, open: !subGroup?.open }; // toggle the open/close
          }

          return subGroup;
        });

        return { ...group, children };
      })
    );
  };

  const handleRedirect = () => {
    isMobile && hideDrawer();
  };

  return menu?.map((menuGroup, groupIndex) => (
    <List
      key={groupIndex}
      component="nav"
      aria-labelledby={groupIndex}
      subheader={
        menuGroup.subHeader ? (
          <ListSubheader
            component="div"
            id={groupIndex}
            disableSticky
            sx={{
              color: alpha(titleTxtColor, titleTxtAlpha),
              backgroundColor: 'transparent',
              m: 0,
              p: 0,
              lineHeight: '30px',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
          >
            {menuGroup.key !== 'company-name' ? t(`menu.${menuGroup.key}`) : menuGroup.subHeader}
          </ListSubheader>
        ) : null
      }
    >
      {menuGroup?.children.map((menuSubGroup, subGroupIndex) =>
        menuSubGroup?.children ? (
          <Fragment key={subGroupIndex}>
            <ListItemButton onClick={() => toggleMenuBranch(menuSubGroup)} sx={sidebarMenuStyle}>
              {menuSubGroup?.icon ? (
                <ListItemIcon>
                  {loadIcon(menuSubGroup?.icon, null, menuSubGroup.selected ? selectedTxtColor : hoverTxtColor)}
                </ListItemIcon>
              ) : null}
              <ListItemText
                primaryTypographyProps={menuSubGroup.selected ? subMenuSelectedStyle : {}}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: menuSubGroup.children.filter((menuItem) => menuItem.selected).length
                      ? 'bold!important'
                      : 'inherit',
                  },
                }}
                primary={t(`menu.${menuSubGroup.key}`)}
              />
              {menuSubGroup.open ? (
                <ExpandLess sx={{ color: hoverTxtColor }} />
              ) : (
                <ExpandMore sx={{ color: hoverTxtColor }} />
              )}
            </ListItemButton>
            <Collapse in={menuSubGroup.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuSubGroup.children.map((menuItem, itemIndex) => {
                  return (
                    <ListItemButton
                      key={itemIndex}
                      selected={menuItem.selected}
                      component={Link}
                      to={menuItem.href}
                      onClick={() => handleRedirect()}
                      sx={sidebarMenuStyle}
                    >
                      <ListItemText sx={{ pl: 6 }} primary={t(`menu.${menuItem.key}`)} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </Fragment>
        ) : (
          <ListItemButton
            key={subGroupIndex}
            selected={menuSubGroup.selected}
            component={Link}
            to={menuSubGroup.href}
            onClick={() => handleRedirect()}
            sx={sidebarMenuStyle}
          >
            <ListItemIcon>
              {menuSubGroup?.icon ? (
                <ListItemIcon>
                  {loadIcon(
                    menuSubGroup?.icon,
                    null,
                    location.pathname === menuSubGroup.href ? selectedTxtColor : hoverTxtColor
                  )}
                </ListItemIcon>
              ) : null}
            </ListItemIcon>
            <ListItemText primary={t(`menu.${menuSubGroup.key}`)} />
          </ListItemButton>
        )
      )}
    </List>
  ));
};
